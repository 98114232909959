
import { defineComponent, ref, onMounted, computed } from "vue";

import { useStore } from "vuex";
import QuotesService from "@/core/services/car/QuotesService";
import PolicyService from "@/core/services/car/PolicyService";
import CustomerService from "@/core/services/car/CustomerService";
import { string } from "yup";
import router from '@/router';
import { Mutations } from "@/store/enums/StoreEnums";

interface LeadDocumentsBodyParm {
  lead_id: number;
}

export default defineComponent({
  name: "quotes-action",
  components: {},
  props: {
    vehicle_type:Number,
    customerId: String,
    driverDetailsId: String,
    leadId: String,
    driverDetails: Array,
    leadStatus: {
      type: Number,
      required: true
    },
    policyStatus: {
      type: Number,
      required: false
    },
    uuid: String,
    isShowMakeInvoice: Boolean,
    invoice: Object,
  },
  created() {
    console.log(this.driverDetails);
  },
  setup(props) {
    const store = useStore();
    let leadDocumentsBodyParm = {} as LeadDocumentsBodyParm;
    let activeLeadId = ref<number>();

    const policy_list = computed(() => {
      return store.state.PolicyModule.leadDocuments;
    });

    const leadList = computed(() => {
      return store.getters.getLeadDetails;
    });

    const isPolicyAssigned = computed(() => {
      if (leadList.value.length > 0) {
        const lead = leadList.value.find(
          (e) => e.id == policy_list.value.lead_id
        );
        // if (lead && lead.policy_list && (lead.active_policy.status == 2)) { //commented by Ashwin B. lead obj was not having policy list in it
        if (lead && lead.active_policy && lead.active_policy.status == 2) {
          return true;
        }
      }
      return false;
    });

    const show = computed(() => {
      if (leadList.value.length > 0) {
        const lead = leadList.value.find(
          (e) => e.id == policy_list.value.lead_id
        );
        if (lead) {
          return lead.lead_status_id;
        }
      }
      return 0;
    });

    const showDealUnfinish = computed(() => {
      if (show.value == 4 && isPolicyAssigned.value) {
        return true;
      }
      return false;
    });

    const sendQuote = (leadId, driverDetailsId) => {
      const params = {
        lead_id: leadId,
        driver_details_id: driverDetailsId,
      };
      if (driverDetailsId) {
        QuotesService.sendQuote(params);
      }
    };

    const in_array_upload_docs = computed(() => {
      const data = [10,7,8,9,4,11];
      if(props.policyStatus !== undefined) {
        return data.includes(props.policyStatus);
      }
      return false;
    });

    const in_array = (item) => {
      const data = [9, 10, 2];
      return data.includes(item);
    };

    const getLeadDocuments = (lead_id) => {     
      CustomerService.setUploadTabLeadId(props.leadId);
      activeLeadId.value = lead_id;
      leadDocumentsBodyParm.lead_id = lead_id;
      PolicyService.getLeadActivePolicyDocuments(leadDocumentsBodyParm);
    };

    const viewDetailsPoliciesDisabledQuicklead = computed(() => {
      if (![2, 9, 10].includes(props.leadStatus)) {
        return true;
      }
      return false;
    })

    const notOmMAs = computed(() => {
      const lsUser = window.localStorage.getItem('user')
      if(lsUser) {
        const user = JSON.parse(lsUser)
        if(![7, 11, 15].includes(user.role_id)) return true
      }

      return false
    })

    /**
     * return data
     *
     */
    return {
      notOmMAs,
      viewDetailsPoliciesDisabledQuicklead,
      sendQuote,
      in_array,
      getLeadDocuments,
      isPolicyAssigned,
      showDealUnfinish,
      in_array_upload_docs,
      leadList,
    };
  },
});
