
import { computed, defineComponent, onMounted, ref, watchEffect, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import QuotesService from "@/core/services/car/QuotesService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import IMultiselect from "@/models/IMultiselect";
import Multiselect from "@vueform/multiselect";
import QuotesAction from "@/components/dropdown/car/QuotesAction.vue";
import message from "element-plus/lib/el-message/src/message";
import { notif, yearList, getCurrentDate,carBikeValueCheck,otherGenders } from "@/store/stateless/store";
import { Mutations } from "@/store/enums/StoreEnums";
import MasterService from "@/core/services/car/MasterService";
import CheckTrimModal from '@/components/modals/forms/CheckTrimModal.vue';
import moment from "moment";
import { registration } from '@/store/stateless/store'
import router from "@/router";

interface CarDetailsBodyParam {
  model_id: string;
  year: string;
}
interface PolicyData {
  companyId: any;
  repair: any;
  value: number;
  discount: number;
  discount_validate:any,
  value_validate:any,
}
export default defineComponent({
  name: "lead-driver-details-view",
  components: { 
    Multiselect, 
    QuotesAction,
    CheckTrimModal
  },
  props: {
    leadDriverDetailsObj: { type: Object, required: true },
    invokeFor: String || undefined,
    key: Number,
    isLoaded: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      dob: "",
      policyBegin: "",
      registrationDate: "",
      driving_experience: [
        { value: "1", label: "0-6 months" },
        { value: "2", label: "6 - 12 months" },
        { value: "3", label: "1 - 2 Years" },
        { value: "4", label: "More than 2 Years" },
      ],
    };
  },

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const inst = getCurrentInstance();
    
    const firstRegistrationDate = (carYear) => {
      const currentDate = new Date()
      const cMonth = currentDate.getMonth() + 1
      const cDay = currentDate.getDate()
      const mMonth = cMonth.toString().length === 1 ? `0${cMonth}` : cMonth
      const mDay = cDay.toString().length === 1 ? `0${cDay}` : cDay
      return `${carYear}-${mMonth}-${mDay}`
    }
   
    let loading = ref(false);
    let carDetailsBodyParam = {} as CarDetailsBodyParam;
    let leadDriverDetailsBodyParam = ref<any>();
    let policyData = ref<Array<PolicyData>>([
      {
        companyId: "",
        repair: "",
        value: 0,
        discount: 0,
        discount_validate:false,
        value_validate:false
      },
      {
        companyId: "",
        repair: "",
        value: 0,
        discount: 0,
        discount_validate:false,
        value_validate:false
      },
      {
        companyId: "",
        repair: "",
        value: 0,
        discount: 0,
        discount_validate:false,
        value_validate:false
      },
    ]);

    const emailRequired = ref(false)

    const isLead = computed(() => {
      return router.currentRoute.value.query.leadsAndTasks
    })
    
    if (props.invokeFor == "editLeadDriverDetails" ||
      props.invokeFor == "generateQuote"
    ) {
      leadDriverDetailsBodyParam.value = {
        driver_details_id: props.leadDriverDetailsObj.id,
        customer_id: props.leadDriverDetailsObj.customer_id,
        car_year: props.leadDriverDetailsObj.lead.car_year,
        manufacturer_id: props.leadDriverDetailsObj.lead.manufacturer_id,
        manufacturer_name: props.leadDriverDetailsObj.lead.make,
        model_id: props.leadDriverDetailsObj.lead.model_id,
        model_name: props.leadDriverDetailsObj.lead.model,
        trim_level_id: props.leadDriverDetailsObj.lead.trim_level_id,
        model_details: props.leadDriverDetailsObj.lead.model_details,
        is_new: props.leadDriverDetailsObj.lead.is_new || 2,
        car_value: props.leadDriverDetailsObj.car_value,
        first_registration_date:
          props.leadDriverDetailsObj.lead.first_registration_date || firstRegistrationDate(props.leadDriverDetailsObj.lead.car_year),
        first_registration_date_format:
          props.leadDriverDetailsObj.lead.first_registration_date_format,

        registration_emirate: 7,
        vehicle_type:props.leadDriverDetailsObj.lead.vehicle_type,
        dob: props.leadDriverDetailsObj.dob,
        dob_format: props.leadDriverDetailsObj.dob_format,
        nationality: parseInt(props.leadDriverDetailsObj.nationality) || null,
        first_driving_license_country:
          parseInt(props.leadDriverDetailsObj.first_driving_license_country) || null,
        driving_experience: parseInt(props.leadDriverDetailsObj.driving_experience) || 4,
        uae_driving_experience:
          parseInt(props.leadDriverDetailsObj.uae_driving_experience) || 4,
        policy_start_date: validateAndSetPolicyStartDate(props.leadDriverDetailsObj.policy_start_date,props.leadDriverDetailsObj.lead.lead_status_id),
        policy_start_date_format:
          props.leadDriverDetailsObj.policy_start_date_format,
        claimed_insurance: props.leadDriverDetailsObj.claimed_insurance || 2,
        no_claim_certificate: props.leadDriverDetailsObj.no_claim_certificate && props.leadDriverDetailsObj.no_claim_certificate != null ? props.leadDriverDetailsObj.no_claim_certificate : "1",
        claims: props.leadDriverDetailsObj.claims,
        gcc_specification: props.leadDriverDetailsObj.lead.gcc_specification || 1,
        personal_use: props.leadDriverDetailsObj.personal_use || 1,

        current_policy_active:
          props.leadDriverDetailsObj.existing_policy_expired == 1 ? 2 : 1,
        existing_policy_expired:
          props.leadDriverDetailsObj.existing_policy_expired || 2,

        fully_comprehensive:
          props.leadDriverDetailsObj.third_party_liability == 1 ? 2 : 1,
        third_party_liability: props.leadDriverDetailsObj.third_party_liability || 2,

        driver_name: props.leadDriverDetailsObj.driver_name,
        lead_id: props.leadDriverDetailsObj.lead_id,
        gender: props.leadDriverDetailsObj.gender,
        policy_type: 0,
        is_vintage: props.leadDriverDetailsObj.lead.is_vintage,
        name: props.leadDriverDetailsObj.customer.name,
        phone_number: props.leadDriverDetailsObj.customer.complete_phone_number,
        email: props.leadDriverDetailsObj.customer.email,
      };
      
    } else if (props.invokeFor == "addDriverDetails") {
       leadDriverDetailsBodyParam.value = {
        customer_id: props.leadDriverDetailsObj.customer_id,
        car_year: props.leadDriverDetailsObj.lead.car_year,
        manufacturer_id: props.leadDriverDetailsObj.lead.manufacturer_id,
        manufacturer_name: props.leadDriverDetailsObj.lead.make,
        model_id: props.leadDriverDetailsObj.lead.model_id,
        model_name: props.leadDriverDetailsObj.lead.model,
        trim_level_id: props.leadDriverDetailsObj.lead.trim_level_id,
        model_details: props.leadDriverDetailsObj.lead.model_details,
        is_new: props.leadDriverDetailsObj.lead.is_new,
        car_value: props.leadDriverDetailsObj.car_value,
        first_registration_date:
          props.leadDriverDetailsObj.lead.first_registration_date,
        first_registration_date_format:
          props.leadDriverDetailsObj.lead.first_registration_date_format,
        registration_emirate: 7,
        vehicle_type:props.leadDriverDetailsObj.lead.vehicle_type,
        dob: null,
        dob_format: null,
        nationality: null,
        first_driving_license_country: null,
        driving_experience: 4,
        uae_driving_experience: 4,
        policy_start_date: validateAndSetPolicyStartDate(props.leadDriverDetailsObj.policy_start_date,props.leadDriverDetailsObj.lead.lead_status_id),
        policy_start_date_format:
          props.leadDriverDetailsObj.policy_start_date_format,
        claimed_insurance: 2,
        no_claim_certificate: "1",
        claims: null,
        gcc_specification: props.leadDriverDetailsObj.lead.gcc_specification,
        personal_use: props.leadDriverDetailsObj.personal_use || 1,
        current_policy_active:
          props.leadDriverDetailsObj.existing_policy_expired == 2 ? 1 : 2,
        fully_comprehensive: props.leadDriverDetailsObj.third_party_liability == 2 ? 1: 2,
        third_party_liability: props.leadDriverDetailsObj.third_party_liability || 2,
        existing_policy_expired:
          props.leadDriverDetailsObj.existing_policy_expired,

        driver_name: "",
        lead_id: props.leadDriverDetailsObj.lead_id,
        gender: props.leadDriverDetailsObj.gender,
        is_vintage: props.leadDriverDetailsObj.lead.is_vintage,
        name: props.leadDriverDetailsObj.customer.name,
        phone_number: props.leadDriverDetailsObj.customer.complete_phone_number,
        email: props.leadDriverDetailsObj.customer.email,
        
      };
      
    } else if (
      props.leadDriverDetailsObj && props.leadDriverDetailsObj.lead && props.invokeFor == "addLeadDriverDetails"
    ) {
      leadDriverDetailsBodyParam.value = {
        customer_id: props.leadDriverDetailsObj.customer_id,
        car_year: 0,
        manufacturer_id: -1,
        manufacturer_name: props.leadDriverDetailsObj.lead.make,
        model_id: -1,
        model_name: props.leadDriverDetailsObj.lead.model,
        trim_level_id: -1,
        model_details: props.leadDriverDetailsObj.lead.model_details,
        is_new: props.leadDriverDetailsObj.lead.is_new,
        car_value: props.leadDriverDetailsObj.car_value,
        first_registration_date:
          props.leadDriverDetailsObj.lead.first_registration_date,
        first_registration_date_format:
          props.leadDriverDetailsObj.lead.first_registration_date_format,

        registration_emirate: 7,
        vehicle_type:props.leadDriverDetailsObj.lead.vehicle_type,
        dob: null,
        dob_format: null,
        nationality: null,
        first_driving_license_country: null,
        driving_experience: 4,
        uae_driving_experience: 4,
        policy_start_date:  validateAndSetPolicyStartDate(props.leadDriverDetailsObj.policy_start_date,props.leadDriverDetailsObj.lead.lead_status_id),
        policy_start_date_format:
          props.leadDriverDetailsObj.policy_start_date_format,
        claimed_insurance: 2,
        no_claim_certificate: "1",
        claims: null,
        gcc_specification: props.leadDriverDetailsObj.lead.gcc_specification,
        personal_use: props.leadDriverDetailsObj.personal_use || 1,
        current_policy_active:
          props.leadDriverDetailsObj.existing_policy_expired == 2 ? 1 : 2,
        fully_comprehensive:
          props.leadDriverDetailsObj.third_party_liability == 2 ? 1 : 2,
        third_party_liability: props.leadDriverDetailsObj.third_party_liability || 2,
        existing_policy_expired:
          props.leadDriverDetailsObj.existing_policy_expired,

        driver_name: "",
        lead_id: props.leadDriverDetailsObj.lead_id,
        gender: props.leadDriverDetailsObj.gender,
        is_vintage: 0,
        name: props.leadDriverDetailsObj.name,
        phone_number: props.leadDriverDetailsObj.phone_number_without_code,
        email: props.leadDriverDetailsObj.email,
      };
      
    } else if (
      props.leadDriverDetailsObj && props.leadDriverDetailsObj.lead && props.invokeFor == "NewCarDetails"
    ) {
      leadDriverDetailsBodyParam.value = {
        customer_id: props.leadDriverDetailsObj.customer_id,
        car_year: 0,
        manufacturer_id: -1,
        manufacturer_name: "",
        model_id: -1,
        model_name: "",
        trim_level_id: -1,
        model_details: "",
        is_new: props.leadDriverDetailsObj.lead.is_new,
        car_value: 0,
        policy_start_date: getCurrentDate.todayFormat(),
        first_registration_date: null,

        registration_emirate: 7,
        vehicle_type: isLead.value ? 1 : props.leadDriverDetailsObj.lead.vehicle_type,
        dob: props.leadDriverDetailsObj.dob,
        nationality: props.leadDriverDetailsObj.nationality,
        first_driving_license_country: props.leadDriverDetailsObj.first_driving_license_country,
        driving_experience: 4,
        uae_driving_experience: 4,
        claimed_insurance: props.leadDriverDetailsObj.claimed_insurance,
        no_claim_certificate: props.leadDriverDetailsObj.no_claim_certificate,
        claims: props.leadDriverDetailsObj.claims,
        gcc_specification: props.leadDriverDetailsObj.lead.gcc_specification,
        personal_use: props.leadDriverDetailsObj.personal_use || 1,
        current_policy_active: props.leadDriverDetailsObj.existing_policy_expired == 2 ? 1 : 2,
        fully_comprehensive: props.leadDriverDetailsObj.third_party_liability == 2 ? 1 : 2,
        third_party_liability: props.leadDriverDetailsObj.third_party_liability || 2,
        existing_policy_expired: props.leadDriverDetailsObj.existing_policy_expired,
        driver_name: props.leadDriverDetailsObj.driver_name,
        lead_id: props.leadDriverDetailsObj.lead_id,
        gender: props.leadDriverDetailsObj.gender,
        is_vintage: 0,
        name: props.leadDriverDetailsObj.customer.name,
        phone_number: props.leadDriverDetailsObj.customer.complete_phone_number,
        email: props.leadDriverDetailsObj.customer.email,
      };
      
    } else {
      if (props.isLoaded) {
        
        leadDriverDetailsBodyParam = ref(props.leadDriverDetailsObj);
        if(leadDriverDetailsBodyParam.value) {
          leadDriverDetailsBodyParam.value.is_vintage = 0
          leadDriverDetailsBodyParam.value.name = props.leadDriverDetailsObj.customer ? ref(props.leadDriverDetailsObj.customer.name) : ''
          leadDriverDetailsBodyParam.value.phone_number = props.leadDriverDetailsObj.customer ? ref(props.leadDriverDetailsObj.customer.complete_phone_number) : ''
          leadDriverDetailsBodyParam.value.email = props.leadDriverDetailsObj.customer ?  ref(props.leadDriverDetailsObj.customer.email) : ''
          leadDriverDetailsBodyParam.value.vehicle_type = 1
          leadDriverDetailsBodyParam.value.car_year = 0
          leadDriverDetailsBodyParam.value.is_new =  props.leadDriverDetailsObj.lead ?  ref(props.leadDriverDetailsObj.lead.is_new) : ''
          leadDriverDetailsBodyParam.value.is_vintage =  props.leadDriverDetailsObj.lead ?  ref(props.leadDriverDetailsObj.lead.is_vintage) : 0
        }
         
      } else {
        leadDriverDetailsBodyParam.value = {};
      }
    }

// console.log(">>>>>>>>>>>>  leadDriverDetailsBodyParam >>>>>");
// console.log(leadDriverDetailsBodyParam.value);

    const rules = ref({
      car_year: {
        show: false,
        message: "Please select car year",
      },
      manufacturer_id: {
        show: false,
        message: "Please select car make",
      },
      model_id: {
        show: false,
        message: "Please select car model",
      },
      trim_level_id: {
        show: false,
        message: "Please select car trim level",
      },
      car_value: {
        show: false,
        message: "Please enter car value ",
      },
      emirates: {
        show: false,
        message: "Please select Registration country",
      },

      nationality: {
        show: false,
        message: "Please select driver nationality",
      },
      dob: {
        show: false,
        message: "Please enter driver date of birth",
      },
      first_registration_date: {
        show: false,
        message: "Please enter first registration date",
      },
      first_driving_license_country: {
        show: false,
        message: "Please select first dl was issued in",
      },
      uae_driving_experience: {
        show: false,
        message: "Please select UAE driving experience",
      },
      driving_experience: {
        show: false,
        message: "Please select international driving experience",
      },
      no_claim_certificate: {
        show: false,
        message: "Please select the no claims certificate",
      },
      claims: {
        show: false,
        message: "Please enter no. of claims in the last 12 months",
      },
      policy_start_date: {
        show: false,
        message: "Please select Policy Start Date",
      },
      driver_name: {
        show: false,
        message: "Please enter driver name",
      },
      gender: {
        show: false,
        message: "Please select the gender ",
      },
      claimed_insurance: {
        show: false,
        message: "Please select claims made",
      },
      policyType: {
        show: false,
        message: "Please select policy type",
      },
      is_new: {
        show: false,
        message: "Please select car is brand new ",
      },
      existing_policy_expired: {
        show: false,
        message: "Please select policy active",
      },
      gcc_specification: {
        show: false,
        message: "Please select gcc specs & no modification",
      },
      personal_use: {
        show: false,
        message: "Please select vehicle use",
      },
      third_party_liability: {
        show: false,
        message: "Please select existing policy TPL only ",
      },
      companyList: {
        show: false,
        message: "Please select company",
      },
      email: {
        show: false,
        message: "Please enter email",
      },
      
    });

    const validationReset = () => {
      rules.value.car_year.show = false;
      rules.value.manufacturer_id.show = false;
      rules.value.model_id.show = false;
      rules.value.trim_level_id.show = false;
      rules.value.emirates.show = false;
      rules.value.nationality.show = false;
      rules.value.dob.show = false;
      rules.value.first_registration_date.show = false;
      rules.value.first_driving_license_country.show = false;
      rules.value.uae_driving_experience.show = false;
      rules.value.driving_experience.show = false;
      rules.value.no_claim_certificate.show = false;
      rules.value.claims.show = false;
      rules.value.policy_start_date.show = false;
      rules.value.driver_name.show = false;
      rules.value.gender.show = false;
      rules.value.claimed_insurance.show = false;
      rules.value.policyType.show = false;
      rules.value.is_new.show = false;
      rules.value.existing_policy_expired.show = false;
      rules.value.personal_use.show = false;
      rules.value.third_party_liability.show = false;
      rules.value.gcc_specification.show = false;
      rules.value.car_value.show = false;
      rules.value.companyList.show = false;
      rules.value.email.show = false;
    };

    // values for selectbox

    // const year = new Date().getFullYear();
    // let yearsList = computed(() => {
    //   return Array.from(
    //     { length: year - 2003 },
    //     (value, index) => 2021 - index
    //   );
    // });

    let years = ref<IMultiselect>({
      placeholder: "Please Select Car Year",
      value: null,
      options: yearList.getYearList(),
      searchable: true,
      clearOnSelect: true,
    });
    let manufacturerList = computed(() => {
      let manu = store.getters.getManufacturersList;
      const exist = manu.some((e) => {
        return e.value == -1;
      });
      if (!exist) {
        manu.unshift({
          label: "Car Make",
          value: -1,
          id: 0,
          manufacturer: "Make",
        });
      }
      /*
       *Need to check the reason for getting undefined
       */
      // else {
      //   manu = manu.forEach((x) => {
      //     if (x.manufacturer != "QL") {
      //       return { ...x };
      //     }
      //   });
      // }

      return manu;
    });
    // let manufacturers = ref<IMultiselect>({
    //   placeholder: "Please Select Car",
    //   value: null,
    //   options: manufacturerList,
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    let modelList = computed(() => {
      const model = store.getters.getModelsList;
      const exist = model.some((e) => {
        return e.value == -1;
      });
      if (!exist) {
        model.unshift({ label: "Car Model", value: -1, id: 0, model: "Model" });
      }
      return model;
    });
    let models = ref<IMultiselect>({
      placeholder: "Please Select Car Model",
      value: null,
      options: modelList,
      searchable: true,
      clearOnSelect: true,
    });
    let trimsList = computed(() => {
      const trim = store.getters.getTrimDetails;
      const exist = trim.some((e) => {
        return e.value == -1;
      });
      if (!exist) {
        trim.unshift({ label: "Trim", value: -1, id: 0, model: "Trim" });
      }
      return trim;
    });
    let trims = ref<IMultiselect>({
      placeholder: "Please Select Car Model",
      value: null,
      options: trimsList,
      searchable: true,
      clearOnSelect: true,
    });
    let countryList = computed(() => {
      return store.getters.getCountry;
    });
    const nationality = ref({
      placeholder: "Please Select Nationality",
      value: null,
      options: countryList,
      searchable: true,
      clearOnSelect: true,
    });
    let dlCountryList = computed(() => {
      return store.getters.getDlCountry;
    });

    // const dlCountry = ref({
    //   placeholder: "Please Select Country",
    //   value: null,
    //   options: dlCountryList,
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    // const emirates = ref({
    //   placeholder: "Please Select Emirate",
    //   value: "3",
    //   options: [
    //     { value: "1", label: "Abu Dhabi" },
    //     { value: "2", label: "Ajman" },
    //     { value: "3", label: "Dubai" },
    //     { value: "4", label: "Fujairah" },
    //     { value: "5", label: "Ras Al Khaimah" },
    //     { value: "6", label: "Sharjah" },
    //     { value: "7", label: "Umm Al Quwain" },
    //   ],
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    const emirates = ref([
      { value: 0, label: "Abu Dhabi" },
      { value: 1, label: "Ajman" },
      { value: 2, label: "Dubai" },
      { value: 3, label: "Fujairah" },
      { value: 4, label: "Ras Al Khaimah" },
      { value: 5, label: "Sharjah" },
      { value: 6, label: "Umm Al Quwain" },
    ]);
    // const uaeExperience = ref({
    //   placeholder: "Please Select Experience",
    //   value: "4",
    //   options: [
    //     { value: "1", label: "0-6 months" },
    //     { value: "2", label: "6 - 12 months" },
    //     { value: "3", label: "1 - 2 Years" },
    //     { value: "4", label: "More than 2 Years" },
    //   ],
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    // const homeExperience = ref({
    //   placeholder: "Please Select Experience",
    //   value: "4",
    //   options: [
    //     { value: "1", label: "0-6 months" },
    //     { value: "2", label: "6 - 12 months" },
    //     { value: "3", label: "1 - 2 Years" },
    //     { value: "4", label: "More than 2 Years" },
    //   ],
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    const uaeExperience = ref([
        { value: 1, label: "0-6 months" },
        { value: 2, label: "6 - 12 months" },
        { value: 3, label: "1 - 2 Years" },
        { value: 4, label: "More than 2 Years" }]);
    const homeExperience = ref([
        { value: 1, label: "0-6 months" },
        { value: 2, label: "6 - 12 months" },
        { value: 3, label: "1 - 2 Years" },
        { value: 4, label: "More than 2 Years" }
      ]);
    const ncc = ref([
        { value: "1", label: "No - I have no certificate" },
        { value: "2", label: "Yes - 1 year with proof" },
        { value: "3", label: "Yes - 2 years with proof" },
        { value: "4", label: "Yes - 3 or more years with proof" }
      ]);
    // const ncc = ref({
    //   placeholder: "Please Select",
    //   value: "1",
    //   options: [
    //     { value: "1", label: "No - I have no certificate" },
    //     { value: "2", label: "Yes - 1 year with proof" },
    //     { value: "3", label: "Yes - 2 years with proof" },
    //     { value: "4", label: "Yes - 3 or more years with proof" },
    //   ],
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    let salesAgentList = computed(() => {
      return store.getters.getDomSalesAgent;
    });
    const salesAgent = ref({
      placeholder: "Please Select",
      value: null,
      options: salesAgentList,
      searchable: true,
      clearOnSelect: true,
    });
    let companiesList = computed(() => {
      return store.getters.getInsuranceCompaniesList;
    });

    let companyList = ref({
      mode: "tags",
      data: [],
      options: companiesList,
      searchable: true,
      createTag: true,
    });

    const currentLeads = computed(() => {
      const leads = store.getters.getLeadDetails
      const lead_id = route?.query?.lead_id
      if(lead_id) {
        return leads.find(x => x.id === parseInt(lead_id.toString()))
      }
      return {}
    })




    onMounted(() => {
        if(leadDriverDetailsBodyParam.value) MasterService.getCarYears({is_vintage: leadDriverDetailsBodyParam.value.is_vintage})
       if(leadDriverDetailsBodyParam.value && (props.invokeFor == "view" ||
        props.invokeFor != "addLeadDriverDetailsQuote"))
      {
        setTimeout(() => {
          
            QuotesService.getManufacturers({
              year: leadDriverDetailsBodyParam.value.car_year,
              is_vintage: leadDriverDetailsBodyParam.value.is_vintage,
              is_show_bike:false
            });
          
        }, 500);
      }
      QuotesService.getCountries();
      MasterService.getPolicyStartDateDays();

      // leadDriverDetailsBodyParam.value.dob = null;
      if(props.invokeFor == "generateQuote" ||
        props.invokeFor == "addLeadDriverDetailsQuote")
      {
          store.commit(Mutations.SET_INSURANCE_POLICY_LIST, {});

      }

      emailRequired.value = leadDriverDetailsBodyParam.value && leadDriverDetailsBodyParam.value.email ? false : true
     
      // const carYears = yearList.getYearList();
      // yearsList.value = carYears.map((x) => {
      //   return {
      //     label: x,
      //     value: x,
      //   };
      // });
      // if (yearsList.value !== undefined) {
      //   const exist = yearsList.value.some((e: any) => {
      //     return e.value === 0;
      //   });
      //   if (!exist) {
      //     yearsList.value.unshift({
      //       label: "Car year",
      //       value: 0,
      //     });
      //   }
      // }

      if(leadDriverDetailsBodyParam.value) leadDriverDetailsBodyParam.value.lead_source = 'Skye'

      setTimeout(() => {
        // && currentLeads.value
        if(props.invokeFor === 'NewCarDetails') {
          

          // leadDriverDetailsBodyParam.value.dob = currentLeads.value?.lead_driver_detail?.dob ? currentLeads.value?.lead_driver_detail?.dob : ''
          // leadDriverDetailsBodyParam.value.nationality = currentLeads.value?.lead_driver_detail?.nationality ? parseInt(currentLeads.value?.lead_driver_detail?.nationality) : null
          // leadDriverDetailsBodyParam.value.first_driving_license_country = currentLeads.value?.lead_driver_detail?.first_driving_license_country ? parseInt(currentLeads.value?.lead_driver_detail?.first_driving_license_country) : null
          // leadDriverDetailsBodyParam.value.driving_experience = currentLeads.value?.lead_driver_detail?.driving_experience ? parseInt(currentLeads.value?.lead_driver_detail?.driving_experience) : 4
          // leadDriverDetailsBodyParam.value.uae_driving_experience = currentLeads.value?.lead_driver_detail?.uae_driving_experience ? parseInt(currentLeads.value?.lead_driver_detail?.uae_driving_experience) : 4
          // leadDriverDetailsBodyParam.value.claimed_insurance = parseInt(currentLeads.value?.lead_driver_detail?.claimed_insurance) > 0 ? currentLeads.value?.lead_driver_detail?.claimed_insurance : ''
          // leadDriverDetailsBodyParam.value.claims = currentLeads.value?.lead_driver_detail?.claims ? currentLeads.value?.lead_driver_detail?.claims : null
          // leadDriverDetailsBodyParam.value.no_claim_certificate = currentLeads.value?.lead_driver_detail?.no_claim_certificate !== "0" ? currentLeads.value?.lead_driver_detail?.no_claim_certificate : "1" 
          // leadDriverDetailsBodyParam.value.gender = currentLeads.value?.lead_driver_detail?.gender

          const carYearRef:any = inst?.refs?.carYearRef
          carYearRef.focus();
        }

        if(props.invokeFor === 'addDriverDetails') {
          const dobRef:any = inst?.refs?.dobRef
          dobRef.isFocus();
        }

        if(props.invokeFor === 'editLeadDriverDetails') {
          // emailRequired.value = 
          const carYearRef:any = inst?.refs?.carYearRef
          const carValueRef:any = inst?.refs?.carValueRef
          if(!carYearRef.disabled) {
            carYearRef.focus();
          } else {
            carValueRef.isFocus();
          }
        }

        if(props.invokeFor === 'addLeadDriverDetailsQuote') {
          const carYearRef:any = inst?.refs?.carYearRef
          carYearRef.focus();
        }
      }, 1500);

      
    });

    const yearsList = computed(() => {

      let defaultValue = [{ label: 'Car Year', value: 0 }]
      
      const carYears = store.getters.getCarYears
      if(carYears.length > 0) {
        defaultValue = carYears.map(x => {
          return {
            label: x,
            value: x
          }
        })

        const exist = defaultValue.some(x => x.value === 0)
        if(!exist) defaultValue.unshift({ label: 'Car Year', value: 0 })

        return defaultValue
      }

      return defaultValue
    })

    const getCarYears = async () => {
      if((props.invokeFor !== "editLeadDriverDetails" && props.leadDriverDetailsObj && props.leadDriverDetailsObj.is_lead_editable) || props.invokeFor !== "addDriverDetails") {
        
        await MasterService.getCarYears({is_vintage: leadDriverDetailsBodyParam.value.is_vintage})
        leadDriverDetailsBodyParam.value.car_year = 0
        leadDriverDetailsBodyParam.value.manufacturer_id = -1
        leadDriverDetailsBodyParam.value.model_id = -1
        leadDriverDetailsBodyParam.value.trim_level_id = -1
        leadDriverDetailsBodyParam.value.car_value = 0
        leadDriverDetailsBodyParam.value.first_registration_date = ''

        QuotesService.resetValues()
      }
    }

    const onManufacturerChange = (event) => {

      QuotesService.resetModelValues();
      rules.value.manufacturer_id.show = false
      leadDriverDetailsBodyParam.value.car_value = 0;
      leadDriverDetailsBodyParam.value.model_id = -1;
      leadDriverDetailsBodyParam.value.trim_level_id = -1;

      QuotesService.getModels({
        manufacturer_id: event,
        year: leadDriverDetailsBodyParam.value.car_year,
        is_vintage: leadDriverDetailsBodyParam.value.is_vintage
      });
      
    };

    const onModelChange = (event) => {
      rules.value.model_id.show = false

      QuotesService.getTrims({
        year: leadDriverDetailsBodyParam.value.car_year,
        model_id: leadDriverDetailsBodyParam.value.model_id,
        is_vintage: leadDriverDetailsBodyParam.value.is_vintage
      }).then((val: any) => {
        if(val) {
          leadDriverDetailsBodyParam.value.trim_level_id = val?.value
        }
      });
      leadDriverDetailsBodyParam.value.car_value = 0;
    };

    let editCarValueCheck = ref(false);
    // change event
    const onYearSelect = (event) => {
      // manufacturers.value.value = null;
      // models.value.value = null;
      // trims.value.value = null;
      QuotesService.resetValues();
      if(props.invokeFor === 'editLeadDriverDetails') {
        editCarValueCheck.value = true;
      }
      leadDriverDetailsBodyParam.value.car_value = 0;
      leadDriverDetailsBodyParam.value.manufacturer_id = -1;
      leadDriverDetailsBodyParam.value.model_id = -1;
      leadDriverDetailsBodyParam.value.trim_level_id = -1;
      leadDriverDetailsBodyParam.value.first_registration_date = '';

      rules.value.car_year.show = false
      // leadDriverDetailsBodyParam.value.car_year = event;
      // if (
      //   !yearList
      //     .getYearValue()
      //     .includes(leadDriverDetailsBodyParam.value.car_year)
      // ) {
        leadDriverDetailsBodyParam.value.is_new = 2;
      // }
      // carDetailsBodyParam.year = event;
      if(!leadDriverDetailsBodyParam.value.is_vintage) {
        leadDriverDetailsBodyParam.value.first_registration_date = firstRegistrationDate(event)
      }
      var payload = {
            year: leadDriverDetailsBodyParam.value.car_year,
            is_vintage: leadDriverDetailsBodyParam.value.is_vintage,
            is_show_bike:false
      };
      
      if(props.invokeFor == "addDriverDetails" || props.invokeFor == "addLeadDriverDetails" || props.invokeFor == "NewCarDetails")
      {
        payload = {
              year: leadDriverDetailsBodyParam.value.car_year,
              is_vintage: leadDriverDetailsBodyParam.value.is_vintage,
              is_show_bike:false
        };
      }
      QuotesService.getManufacturers(payload);
    };

    const estimatedCarValue = computed(() => {
      return store.getters.getEstimatedCarValue
    })

    const onTrimChange = async (event) => {
      MasterService.setTrimModal(false);
      rules.value.trim_level_id.show = false;
      if(props.invokeFor == "editLeadDriverDetails") {
      MasterService.checkTrim({
        'new_trim': leadDriverDetailsBodyParam.value.trim_level_id,
        'prev_trim': props.leadDriverDetailsObj.lead.trim_level_id
      }).then(
        (result: any) => {
          MasterService.setTrimModal(result);
        }
      );
      }
        
      QuotesService.getEstimatedValue(leadDriverDetailsBodyParam.value).then(
        (result: any) => {
          leadDriverDetailsBodyParam.value.car_value = result.data
            ? result.data.value.old_min
            : 0;
        }
      );
    };
    // const onTrimChange = async (event) => {
    //   // const trims = store.getters.getTrimDetails;

    //   leadDriverDetailsBodyParam.value.trim_level_id = event;

    //   Promise.all([
    //     QuotesService.getEstimatedValue(leadDriverDetailsBodyParam.value),
    //   ]).then((data) => {
    //     const estimatedCarValue = store.getters.getEstimatedCarValue;
    //     console.log('estimatedCarValue',estimatedCarValue)
    //     // leadDriverDetailsBodyParam.value.car_value =
    //     //   estimatedCarValue.old_max !== undefined
    //     //     ? estimatedCarValue.old_max
    //     //     : "";
    //   });
    // };
    const getBrandNew = (event) => {
      leadDriverDetailsBodyParam.value.is_new = event.target.value;
      if(props.invokeFor === 'addLeadDriverDetails' || props.invokeFor === 'NewCarDetails') {
        leadDriverDetailsBodyParam.value.car_value = leadDriverDetailsBodyParam.value.is_new === 1 || leadDriverDetailsBodyParam.value.is_new === '1' ? estimatedCarValue.value.new_min : estimatedCarValue.value.old_min;
      } else {
        if(props.invokeFor !== 'editLeadDriverDetails') {
          leadDriverDetailsBodyParam.value.car_value = 0;
        } else {
          if(editCarValueCheck.value) {
            leadDriverDetailsBodyParam.value.car_value = leadDriverDetailsBodyParam.value.is_new === 1 || leadDriverDetailsBodyParam.value.is_new === '1' ? estimatedCarValue.value.new_min : estimatedCarValue.value.old_min;
          }
        }
      }
    };

    const getEmirates = (event) => {
      leadDriverDetailsBodyParam.value.registration_emirate = event;
    };
    const clearEmirates = () => {
      leadDriverDetailsBodyParam.value.registration_emirate = null;
    };
    const getNationality = () => {
      // leadDriverDetailsBodyParam.value.nationality = event;
      leadDriverDetailsBodyParam.value.first_driving_license_country =
        leadDriverDetailsBodyParam.value.nationality;
      // dlCountry.value.value = event
    };
    const clearNationality = () => {
      leadDriverDetailsBodyParam.value.nationality = null;
    };
    const getDlNationality = (event) => {
      leadDriverDetailsBodyParam.value.first_driving_license_country = event;
    };
    const clearDlNationality = () => {
      leadDriverDetailsBodyParam.value.first_driving_license_country = null;
    };
    const getRegistraionDate = (registrationDate) => {
      var registrationTS = new Date(registrationDate.event.target);
      leadDriverDetailsBodyParam.value.first_registration_date =
        registrationTS.getFullYear() +
        "-" +
        (registrationTS.getMonth() + 1) +
        "-" +
        registrationTS.getDate();
    };
    const getPolicyBeginDate = (policyBegin) => {
      // var policyTS = new Date(policyBegin.target.value);
      // leadDriverDetailsBodyParam.value.policy_start_date =
      //   policyTS.getFullYear() +
      //   "-" +
      //   (policyTS.getMonth() + 1) +
      //   "-" +
      //   policyTS.getDate();
    };
    const getHomeDrivingExperience = (event) => {
      leadDriverDetailsBodyParam.value.driving_experience = event;
    };
    const clearHomeDrivingExperience = (event) => {
      leadDriverDetailsBodyParam.value.driving_experience = null;
    };
    const getUaeDrivingExperience = (event) => {
      leadDriverDetailsBodyParam.value.uae_driving_experience = event;
    };
    const clearUaeDrivingExperience = () => {
      leadDriverDetailsBodyParam.value.uae_driving_experience = null;
    };
    const getNoClaim = (event) => {
      leadDriverDetailsBodyParam.value.no_claim_certificate = event;
    };
    const clearNoClaim = () => {
      leadDriverDetailsBodyParam.value.no_claim_certificate = null;
    };
    const getGccSpec = (event) => {
      leadDriverDetailsBodyParam.value.gcc_specification = event.target.value;
    };
    const getVehicleUse = (event) => {
      leadDriverDetailsBodyParam.value.personal_use = event.target.value;
    };
    const getThirdParty = (event) => {
      leadDriverDetailsBodyParam.value.third_party_liability = parseInt(
        event.target.value
      );
      leadDriverDetailsBodyParam.value.fully_comprehensive =
        event.target.value == 1 ? 2 : 1;
    };

    const editClaimsCertificate = ref(null);
    const editClaims = ref(null);
    const resetClaims = () => {
      if (leadDriverDetailsBodyParam.value.claimed_insurance == "1") {
        // editClaimsCertificate.value =
        //   leadDriverDetailsBodyParam.value.no_claim_certificate;

        // ncc.value.value = "";
        leadDriverDetailsBodyParam.value.no_claim_certificate = null;
        leadDriverDetailsBodyParam.value.claims = "1";
      } else {
        // editClaims.value = leadDriverDetailsBodyParam.value.claims;
        leadDriverDetailsBodyParam.value.claims = null;
        leadDriverDetailsBodyParam.value.no_claim_certificate = "1";
        // leadDriverDetailsBodyParam.value.no_claim_certificate =
        //   editClaimsCertificate.value ? editClaimsCertificate.value : 1;
        // ncc.value.value = leadDriverDetailsBodyParam.value.no_claim_certificate;
      }
    };
    const getDOB = (dob) => {
      var dobTS = new Date(dob);
      leadDriverDetailsBodyParam.value.dob =
        dobTS.getFullYear() +
        "-" +
        (dobTS.getMonth() + 1) +
        "-" +
        dobTS.getDate();
    };

    const getPolicyExpiry = (event) => {
      leadDriverDetailsBodyParam.value.existing_policy_expired = parseInt(
        event.target.value
      );
      leadDriverDetailsBodyParam.value.current_policy_active =
        event.target.value == 2 ? 1 : 2;
      // leadDriverDetailsBodyParam.value.existing_policy_expired == 1 ? 2 : 1;
    };

    const onCompanyChange = (event) => {
      // console.log("event", event);
      companyList.value.data = event;
      // console.log("companyList", companyList.value.data);
      // console.log("policyData", policyData.value);

      policyData.value.forEach((value, index) => {
        policyData.value[index].companyId = event[index] !== undefined ? event[index] : "";
        policyData.value[index].repair = "";
        policyData.value[index].value = 0;
        policyData.value[index].discount = 0;
        policyData.value[index].discount_validate = false;
        policyData.value[index].value_validate = false;
      });
    };
    const onPolicyTypeChange = (event) => {
      const data = {
        policy_type: leadDriverDetailsBodyParam.value.policy_type,
      };
      Promise.all([QuotesService.getInsuranceCompanies(data)]).then((data) => {
        companyList.value.data = [];
        if (leadDriverDetailsBodyParam.value.policy_type == "0") {
          companyList.value = {
            mode: "tags",
            data: [],
            options: companiesList,
            searchable: true,
            createTag: true,
          };
        }
        policyData.value.forEach((value, index) => {
          policyData.value[index].companyId = "";
          policyData.value[index].repair = "";
          policyData.value[index].value = 0;
          policyData.value[index].discount = 0;
          policyData.value[index].discount_validate = false;
          policyData.value[index].value_validate = false;
        });
      });
    };
    const isDecimalValid = ref(true);
    const addQuote = (invokeFor) => {
      isDecimalValid.value = true;

      validationReset();

      leadDriverDetailsBodyParam.value.personal_use = 1
      if (invokeFor == "addLeadDriverDetails") {
        leadDriverDetailsBodyParam.value.customer_id =
          route.params.customerId.toString();
      }
      if (
        invokeFor == "generateQuote" ||
        invokeFor == "addLeadDriverDetailsQuote"
      ) {
        leadDriverDetailsBodyParam.value.policy_data = [];
        leadDriverDetailsBodyParam.value.policy_data = policyData.value;
      }

      // leadDriverDetailsBodyParam.value.uae_driving_experience =
      //   uaeExperience.value.value;
      // leadDriverDetailsBodyParam.value.driving_experience =
      //   homeExperience.value.value;
      // leadDriverDetailsBodyParam.value.no_claim_certificate = ncc.value.value;

      isFormValid.value = true;
      validateDriverDetails();
 
      if (isFormValid.value == true) {
        loading.value = true;
    
        if (
          invokeFor == "addLeadDriverDetails" ||
          invokeFor == "addDriverDetails" ||
          invokeFor == 'NewCarDetails'
        ) {
          Promise.all([
            QuotesService.addQuote(leadDriverDetailsBodyParam.value),
          ])
            .then((data) => {
              notif.simple("Customer", "success", "You have successfully added a new Lead!");
              setTimeout(() => {
                loading.value = false;
              }, 3000);
            })
            .catch(() => {
              loading.value = false;
            });
          
        } else if (invokeFor == "editLeadDriverDetails") {
          Promise.all([
            QuotesService.editQuote(leadDriverDetailsBodyParam.value, checkTrimLevel.value),
          ])
            .then((data) => {
              notif.simple("Customer Details Edited", "success", "You have successfully edited the customer details.");
              setTimeout(() => {
                loading.value = false;
              }, 3000);
            })
            .catch(() => {
              loading.value = false;

            });
            // loading.value = false;
        } else if (
          invokeFor == "generateQuote" ||
          invokeFor == "addLeadDriverDetailsQuote"
        ) {
          loading.value = true;
          Promise.all([
            QuotesService.addManualQuotes(leadDriverDetailsBodyParam.value),
          ]).then((data) => {
            setTimeout(() => {
                loading.value = false;
              }, 3000);
            // const notifMessage = invokeFor == "generateQuote" ? "You have successfully generate a quote!" : "Customer has been added successfully!";
            // if(invokeFor !== 'generateQuote') {
            //   notif.simple("Customer Added", "success", notifMessage);
            // }
            reset();
            // console.log("data", data);
          })
          .catch(() => {
            loading.value = false;

          });
          
        } else {
          loading.value = false;
          // console.log("add request");
        }
      } else {
        loading.value = false;
        focusField()
        // console.log("NotSubmitted");
        scrollPage();
      }
    };
    const scrollPage = () => {
      if (
        rules.value.car_year.show ||
        rules.value.manufacturer_id.show ||
        rules.value.model_id.show ||
        rules.value.trim_level_id.show ||
        rules.value.car_value.show ||
        rules.value.emirates.show ||
        rules.value.first_registration_date.show ||
        rules.value.existing_policy_expired.show ||
        rules.value.existing_policy_expired.show ||
        rules.value.policy_start_date.show ||
        rules.value.gcc_specification.show ||
        rules.value.personal_use.show ||
        rules.value.third_party_liability.show
      ) {
        window.scrollTo(0, 0);
      } 
      // else if (
      //   rules.value.dob.show ||
      //   rules.value.nationality.show ||
      //   rules.value.first_driving_license_country.show ||
      //   rules.value.driving_experience.show ||
      //   rules.value.uae_driving_experience.show ||
      //   rules.value.claimed_insurance.show ||
      //   rules.value.claims.show ||
      //   rules.value.no_claim_certificate.show ||
      //   rules.value.car_year.show ||
      //   rules.value.gender.show ||
      //   rules.value.driver_name.show
      // ) {
      //   window.scrollTo(0, 150);
      // }
    };
    const reset = () => {
      // manufacturers.value.value = null
      models.value.value = null;
      trims.value.value = null;
      leadDriverDetailsBodyParam.value.registration_emirate = 7;
      // nationality.value.value = null;
      // dlCountry.value.value = null;
      // homeExperience.value.value = "";
      // uaeExperience.value.value = "";
      // ncc.value.value = "";
      leadDriverDetailsBodyParam.value.car_value = 0;
      leadDriverDetailsBodyParam.value = {};
    };

    //validate the form
    let isFormValid = ref<boolean>(true);
    const validateDriverDetails = () => {
      let singleCount =
        leadDriverDetailsBodyParam.value.driver_name.match(/[']/g);
      let minusCount =
        leadDriverDetailsBodyParam.value.driver_name.match(/[-]/g);
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;
      // let email_ref = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}?$/;

      let singleCountChecker = true;
      let minusCountChecker = true;

      if (singleCount) {
        singleCountChecker = singleCount.length > 1 ? false : true;
      }
      if (minusCount) {
        minusCountChecker = minusCount.length > 1 ? false : true;
      }

      if (!singleCountChecker || !minusCountChecker) {
        rules.value.driver_name.message = `Only character a-z, - and ' allowed`;
        rules.value.driver_name.show = true;
        isFormValid.value = false;
      }

      if (
        !decimalPlaces.test(String(leadDriverDetailsBodyParam.value.car_value))
      ) {
        rules.value.car_value.message =
          "Please enter upto 2 decimal places only";
        rules.value.car_value.show = true;
        isFormValid.value = false;
        isDecimalValid.value = false;
      }

      if (
        leadDriverDetailsBodyParam.value.car_year == -1 ||
        leadDriverDetailsBodyParam.value.car_year == ""
      ) {
        rules.value.car_year.show = true;
        isFormValid.value = false;
      }
      if (leadDriverDetailsBodyParam.value.manufacturer_id == -1) {
        rules.value.manufacturer_id.show = true;
        isFormValid.value = false;
      }
      if (leadDriverDetailsBodyParam.value.model_id == -1) {
        rules.value.model_id.show = true;
        isFormValid.value = false;
      }
      // if (leadDriverDetailsBodyParam.value.trim_level_id == -1) {
      //   rules.value.trim_level_id.show = true;
      //   isFormValid.value = false;
      // }
      if (
        leadDriverDetailsBodyParam.value.car_value == null ||
        leadDriverDetailsBodyParam.value.car_value == 0
      ) {
        rules.value.car_value.show = true;
        isFormValid.value = false;
      }
      if (
        leadDriverDetailsBodyParam.value.registration_emirate == null
        // leadDriverDetailsBodyParam.value.registration_emirate == -1
      ) {
        rules.value.emirates.show = true;
        isFormValid.value = false;
      }
      if (
        leadDriverDetailsBodyParam.value.nationality == null ||
        leadDriverDetailsBodyParam.value.nationality == 0
      ) {
        rules.value.nationality.show = true;
        isFormValid.value = false;
      }
      if (
        leadDriverDetailsBodyParam.value.dob == null ||
        leadDriverDetailsBodyParam.value.dob == ""
      ) {
        rules.value.dob.show = true;
        isFormValid.value = false;
      }
      if (leadDriverDetailsBodyParam.value.dob && checkdateLeadDriverDob.value < 18) {
        rules.value.dob.message = "Date of birth must not less than 18 years old";
        rules.value.dob.show = true;
        isFormValid.value = false;
      }
      if (
        leadDriverDetailsBodyParam.value.first_registration_date == null ||
        leadDriverDetailsBodyParam.value.first_registration_date == ""
      ) {
        rules.value.first_registration_date.show = true;
        isFormValid.value = false;
      }
      // Hide for Lebanon
      // if (
      //   leadDriverDetailsBodyParam.value.first_driving_license_country ==
      //     null ||
      //   leadDriverDetailsBodyParam.value.first_driving_license_country == "0"
      // ) {
      //   rules.value.first_driving_license_country.show = true;
      //   isFormValid.value = false;
      // }
      // if (
      //   leadDriverDetailsBodyParam.value.uae_driving_experience == null ||
      //   leadDriverDetailsBodyParam.value.uae_driving_experience == -1
      // ) {
      //   rules.value.uae_driving_experience.show = true;
      //   isFormValid.value = false;
      // }
      // if (
      //   leadDriverDetailsBodyParam.value.driving_experience == null ||
      //   leadDriverDetailsBodyParam.value.driving_experience == -1
      // ) {
      //   rules.value.driving_experience.show = true;
      //   isFormValid.value = false;
      // }
      // if (
      //   (leadDriverDetailsBodyParam.value.no_claim_certificate == null ||
      //     leadDriverDetailsBodyParam.value.no_claim_certificate == "") &&
      //   leadDriverDetailsBodyParam.value.claimed_insurance == 2
      // ) {
      //   rules.value.no_claim_certificate.show = true;
      //   isFormValid.value = false;
      // }

      // if (
      //   leadDriverDetailsBodyParam.value.claimed_insurance == 1 &&
      //   (!leadDriverDetailsBodyParam.value.claims ||
      //     leadDriverDetailsBodyParam.value.claims < 0)
      // ) {
      //   rules.value.claims.show = true;
      //   isFormValid.value = false;
      // }

      if (
        leadDriverDetailsBodyParam.value.policy_start_date == null ||
        leadDriverDetailsBodyParam.value.policy_start_date == ""
      ) {
        rules.value.policy_start_date.show = true;
        isFormValid.value = false;
      }
      if (
        !leadDriverDetailsBodyParam.value.driver_name ||
        (leadDriverDetailsBodyParam.value.driver_name &&
          leadDriverDetailsBodyParam.value.driver_name.length != 3)
      ) {
        if (
          leadDriverDetailsBodyParam.value.driver_name &&
          leadDriverDetailsBodyParam.value.driver_name.length < 3
        ) {
          rules.value.driver_name.message =
            "Driver name must not be less than 3 characters";
          rules.value.driver_name.show = true;
          isFormValid.value = false;
        }

        if (
          leadDriverDetailsBodyParam.value.driver_name == "" ||
          leadDriverDetailsBodyParam.value.driver_name == null
        ) {
          rules.value.driver_name.message = "Please enter driver name";
          rules.value.driver_name.show = true;
          isFormValid.value = false;
        }
      } else {
        rules.value.driver_name.show = false;
      }

      if (leadDriverDetailsBodyParam.value.gender == null) {
        rules.value.gender.show = true;
        if(props.invokeFor == "editLeadDriverDetails") {
          if(checkTrimLevel.value) {
            isFormValid.value = true;
          } else {
            isFormValid.value = false;
          }
        } else {
          isFormValid.value = false;
        }
      }
      if(!leadDriverDetailsBodyParam.value.email) {
        rules.value.email.show = true
        isFormValid.value = false
      }
      
      if(!/.+@.+\..+/.test(String(leadDriverDetailsBodyParam.value.email).toLocaleLowerCase())) {
        rules.value.email.message = 'Email address must be valid'
        rules.value.email.show = true
        isFormValid.value = false
      }
      // if (
      //   leadDriverDetailsBodyParam.value.claimed_insurance == "" ||
      //   leadDriverDetailsBodyParam.value.claimed_insurance == null
      // ) {
      //   rules.value.claimed_insurance.show = true;
      //   isFormValid.value = false;
      // }

      if(props.invokeFor == "generateQuote" ||
          props.invokeFor == "addLeadDriverDetailsQuote")
      {
        if (leadDriverDetailsBodyParam.value.policy_type == null ||
            leadDriverDetailsBodyParam.value.policy_type == 0) 
        {
          rules.value.policyType.show = true;
          isFormValid.value = false;
        }

        if (companyList.value.data == null ||
            companyList.value.data.length == 0) 
        {
          rules.value.companyList.show = true;
          isFormValid.value = false;
        }


        policyData.value.forEach((value, index) => {

          if(policyData.value[index].value > 0 &&
            !decimalPlaces.test(String(policyData.value[index].value)))
          {
            policyData.value[index].value_validate = true;
            isFormValid.value = false;
          }


          if(policyData.value[index].discount > 0 &&
          !decimalPlaces.test(String(policyData.value[index].discount)))
          {
            policyData.value[index].discount_validate = true;
            isFormValid.value = false;
          }

        });
     }
      

      if (
        leadDriverDetailsBodyParam.value.is_new == null ||
        leadDriverDetailsBodyParam.value.is_new == ""
      ) {
        rules.value.is_new.show = true;
        isFormValid.value = false;
      }
      // if (
      //   leadDriverDetailsBodyParam.value.existing_policy_expired == null ||
      //   leadDriverDetailsBodyParam.value.existing_policy_expired == ""
      // ) {
      //   rules.value.existing_policy_expired.show = true;
      //   isFormValid.value = false;
      // }
      // if (
      //   leadDriverDetailsBodyParam.value.gcc_specification == null ||
      //   leadDriverDetailsBodyParam.value.gcc_specification == ""
      // ) {
      //   rules.value.gcc_specification.show = true;
      //   isFormValid.value = false;
      // }
      if (
        leadDriverDetailsBodyParam.value.personal_use == null ||
        leadDriverDetailsBodyParam.value.personal_use == ""
      ) {
        rules.value.personal_use.show = true;
        isFormValid.value = false;
      }
      // if (
      //   leadDriverDetailsBodyParam.value.third_party_liability == null ||
      //   leadDriverDetailsBodyParam.value.third_party_liability == ""
      // ) {
      //   rules.value.third_party_liability.show = true;
      //   isFormValid.value = false;
      // }
    };
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });
    let fetchCarLeadObj = computed(() => {
      return store.getters.getCarLead;
    });

    let getDuplicateDataExist = computed(() => {
      return store.getters.getDuplicateQuotesErrorMessage;
    });

    if (
      (props.invokeFor == "editLeadDriverDetails" ||
        props.invokeFor == "generateQuote") &&
      leadDriverDetailsBodyParam.value.driver_details_id != ""
    ) {
      years.value.value = leadDriverDetailsBodyParam?.value.car_year;
      // manufacturers.value.value =
      //   leadDriverDetailsBodyParam?.value.manufacturer_id;
      models.value.value = leadDriverDetailsBodyParam?.value.model_id;
      // trims.value.value = leadDriverDetailsBodyParam?.value.trim_level_id;
      dlCountryList.value.value =
        leadDriverDetailsBodyParam?.value.first_driving_license_country;

      // homeExperience.value.value =
      //   leadDriverDetailsBodyParam?.value.driving_experience;
      // uaeExperience.value.value =
      //   leadDriverDetailsBodyParam?.value.uae_driving_experience;
      // ncc.value.value = leadDriverDetailsBodyParam?.value.no_claim_certificate;
      // emirates.value.value =
      //   leadDriverDetailsBodyParam?.value.registration_emirate;
      nationality.value.value = leadDriverDetailsBodyParam?.value.nationality;
      // dlCountry.value.value =
      //   leadDriverDetailsBodyParam?.value.first_driving_license_country;
    } else if (
      (props.invokeFor == "addDriverDetails" ||
        props.invokeFor == "addLeadDriverDetailsQuote") &&
      leadDriverDetailsBodyParam.value.driver_details_id != ""
    ) {
      years.value.value = leadDriverDetailsBodyParam?.value.car_year;
      // manufacturers.value.value =
      //   leadDriverDetailsBodyParam?.value.manufacturer_id;
      models.value.value = leadDriverDetailsBodyParam?.value.model_id;
      // trims.value.value = leadDriverDetailsBodyParam?.value.trim_level_id;
      // emirates.value.value =
      //   leadDriverDetailsBodyParam?.value.registration_emirate;
    }

    const yearMinuseighteen = computed(() => {
      const datenow = new Date();
      const day = datenow.getDate();
      const month = datenow.getUTCMonth();
      const year = datenow.getFullYear() - 18;
      return new Date(year, month, day);
    });
    const yearMinusOne = computed(() => {
      const datenow = new Date();
      const day = datenow.getDate();
      const month = datenow.getUTCMonth();
      const year = datenow.getFullYear();
      return new Date(year, month, day);
    });
    const disabledDate = (time: any) => {
      return time.getTime() > new Date(yearMinuseighteen.value);
    };
    const disableDateOne = (time: any) => {
      return time.getTime() < new Date(yearMinusOne.value);
    };

    const checkdateLeadDriverDob = computed(() => {
      return dateDif(leadDriverDetailsBodyParam.value.dob);
    });

    const dateDif = (date: any) => {
      if (date) {
        date = date.split("-");
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var yy = parseInt(date[0]);
        var mm = parseInt(date[1]);
        var dd = parseInt(date[2]);
        var years, months, days;
        // months
        months = month - mm;
        if (day < dd) {
          months = months - 1;
        }
        // years
        years = year - yy;
        if (month * 100 + day < mm * 100 + dd) {
          years = years - 1;
          months = months + 12;
        }
        // days
        days = Math.floor(
          (today.getTime() -
            new Date(yy + years, mm + months - 1, dd).getTime()) /
            (24 * 60 * 60 * 1000)
        );
        //
        // return {years: years, months: months, days: days};
        return years;
      }
      return 0;
    };

    // let yearsList = ref<Array<any>>();
    const disableIsNew = computed(() => {
      if (
        yearList
          .getYearValue()
          .includes(parseInt(leadDriverDetailsBodyParam.value.car_year))
      ) {
        return false;
      } else {
        return true;
      }
    });

    watchEffect(() => {
      // if(getDuplicateDataExist && getDuplicateDataExist.value.status) {
      //   notif.simple("Please note", "warning", getDuplicateDataExist.value.message, 0)
      // }
    })

    const currentyear = computed(() => {
      return new Date().getFullYear();
    })

    const dobYear = computed(() => {
      return currentyear.value - 18;
    })

    const day = computed(() => {
      const today = new Date().getDate();
      return today > 9 ? today : '0'+today;
    })

    const currentDay = computed(() => {
      const today = new Date().getDate();
      return today > 9 ? today : '0'+today;
    })

    const currentMonth = computed(() => {
      const month = new Date().getMonth() + 1;
      return month > 9 ? month : '0'+month;
    })

    const carYearMin = computed(() => {
      const carYear = parseInt(leadDriverDetailsBodyParam.value.car_year);
      return carYear - 2;
    })

    const carYearMax = computed(() => {
      const carYear = parseInt(leadDriverDetailsBodyParam.value.car_year);
      return carYear + 1;
    })


    const focusField = () => {
      const { email, car_year, manufacturer_id, model_id, car_value, first_registration_date, policy_start_date, dob, nationality, first_driving_license_country, gender, driver_name } = rules.value

      if(email.show) {
        const emailRef:any = inst?.refs.emailRef
        emailRef.focus()
      }

      if(car_year.show && !email.show) {
        const carYearRef:any = inst?.refs.carYearRef
        carYearRef.focus()
      }

      if(!car_year.show  && 
          !email.show &&
          manufacturer_id.show) {
        const makeRef:any = inst?.refs.makeRef
        makeRef.focus()
      }

      if(!car_year.show  && 
          !email.show &&
          !manufacturer_id.show &&
          model_id.show) {
        const modelRef:any = inst?.refs.modelRef
        modelRef.focus()
      }

      if(!car_year.show  && 
          !email.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          car_value.show) {
        const carValueRef:any = inst?.refs.carValueRef
        carValueRef.isFocus()
      }

      if(!car_year.show  && 
          !email.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show && 
          first_registration_date.show) {
        const registrationRef:any = inst?.refs.registrationRef
        registrationRef.isFocus()
      }

      if(!car_year.show  && 
          !email.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show && 
          !first_registration_date.show &&
          policy_start_date.show
          ) {
        const expectedPolicyRef:any = inst?.refs.expectedPolicyRef
        expectedPolicyRef.isFocus()
      }

      if(!car_year.show  && 
          !email.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show && 
          !first_registration_date.show &&
          !policy_start_date.show &&
          dob.show
          ) {
        const dobRef:any = inst?.refs.dobRef
        dobRef.isFocus()
      }

      if(!car_year.show  && 
          !email.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show && 
          !first_registration_date.show &&
          !policy_start_date.show &&
          !dob.show &&
          nationality.show
          ) {
        const natioanlityRef:any = inst?.refs.natioanlityRef
        natioanlityRef.focus()
      }

      if(!car_year.show  && 
          !email.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show && 
          !first_registration_date.show &&
          !policy_start_date.show &&
          !dob.show &&
          !nationality.show &&
          first_driving_license_country.show
          ) {
        const dlRef:any = inst?.refs.dlRef
        dlRef.focus()
      }

      if(!car_year.show  && 
          !email.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show && 
          !first_registration_date.show &&
          !policy_start_date.show &&
          !dob.show &&
          !nationality.show &&
          !first_driving_license_country.show &&
          gender.show
          ) {
        const genderRef:any = inst?.refs.genderRef
        genderRef.focus()
      }

      if(!car_year.show  && 
          !email.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show && 
          !first_registration_date.show &&
          !policy_start_date.show &&
          !dob.show &&
          !nationality.show &&
          !first_driving_license_country.show &&
          !gender.show &&
          driver_name.show
          ) {
        const driverNameRef:any = inst?.refs.driverNameRef
        driverNameRef.focus()
      }

      
    }

    const toEmail = () => {
        const emailRef:any = inst?.refs.emailRef
        emailRef.focus()
    }

    const toExpectPolicyActiveDate = () => {
        const policyActiveRef:any = inst?.refs.policyActiveRef
        policyActiveRef.focus()
    }

    const toExpectPolicyStartDate = () => {
        const expectedPolicyRef:any = inst?.refs.expectedPolicyRef
        expectedPolicyRef.isFocus()
    }

    const toRegisrationEmirate = () => {
        const registrationEmirateRef:any = inst?.refs.registrationEmirateRef
        registrationEmirateRef.focus()
    }

    const toVehicleRegistraion = () => {
        const vehicleUseRef:any = inst?.refs.vehicleUseRef
        vehicleUseRef.focus()
    }

    const toRegistrationDateRef = () => {
        const registrationRef:any = inst?.refs.registrationRef
        if(!registrationRef.disabled) {
          registrationRef.isFocus()
        } else {
          toRegisrationEmirate()
        }
    }

    const toGccSpec = () => {
        const gccSpecRef:any = inst?.refs.gccSpecRef
        gccSpecRef.focus()
    }

    const toDriverNationality = () => {
        const natioanlityRef:any = inst?.refs.natioanlityRef
        natioanlityRef.focus()
    }

    const toGender = () => {
        const genderRef:any = inst?.refs.genderRef
        genderRef.focus()
    }

    const toTPL = () => {
      const tplRef:any = inst?.refs.expectedPolicyRef
        tplRef.isFocus()
    }

    const toDob = () => {
      const dobRef:any = inst?.refs.dobRef
      dobRef.isFocus()
    }

    const toDL = () => {
      const dlRef:any = inst?.refs.dlRef
        dlRef.focus()
    }

    const fixFigure = () => {
      leadDriverDetailsBodyParam.value.car_value = roundUp(parseFloat(leadDriverDetailsBodyParam.value.car_value.toString().replace(',', '')))
      if(isNaN(leadDriverDetailsBodyParam.value.car_value)) {
        leadDriverDetailsBodyParam.value.car_value = 0
      }
    }

    const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

     const policyRestrictDates = computed(() => {
      return store.getters.getPolicyStartDateXDaysRestrict
    })
    
    const policyRestrictDatesUpcoming = computed(() => {
      return store.getters.getPolicyStartDateXDaysRestrictUpcoming
    })

    const disabledDates = computed(() => {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - policyRestrictDates.value)
      return moment(currentDate).format('YYYY-MM-DD')
    })

    const disableUpcomingDate = computed(() => {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + policyRestrictDatesUpcoming.value)
      return moment(currentDate).format('YYYY-MM-DD')
    })

    const clearIt = () => {
      leadDriverDetailsBodyParam.value.trim_level_id = -1;
      MasterService.setTrimModal(false);
    }

    const redirectToBike = () => {
      addQuote('editLeadDriverDetails');
      MasterService.setTrimModal(false);
    }
  
    const checkTrimLevel = computed(() => {
      return store.getters.getCheckTrimLevel;
    });

    const toLeadsAndTasks = computed(() => {
      return router.currentRoute.value.query.leadsAndTasks
    }) 

    /**
   * If the lead is in renewal, you need to check the expected policy start date. 
   * Without validation, when editing the lead details without changing the expected policy start date, 
   * the old date is being updated.
   */
  function validateAndSetPolicyStartDate(startDate='',leadStatusId=''){
      return (leadStatusId && [5,7,8].includes(parseInt(leadStatusId)) && startDate) 
                  ? ((moment(startDate, "YYYY-MM-DD").year() < moment().year()) ? null : moment().format('YYYY-MM-DD'))
                  : (startDate ? startDate : moment().format('YYYY-MM-DD'));
  }

    return {
      isFormValid,
      isLead,
      toLeadsAndTasks,
      emailRequired,
      toEmail,
      toGender,
      registration,
      checkTrimLevel,
      disableUpcomingDate,
      disabledDates,
      getCarYears,
      fixFigure,
      currentLeads,
      toExpectPolicyActiveDate,
      toDriverNationality,
      toRegisrationEmirate,
      toRegistrationDateRef,
      toTPL,
      toVehicleRegistraion,
      toDL,
      toDob,
      toGccSpec,
      toExpectPolicyStartDate,
      focusField,
      estimatedCarValue,
      carYearMin,
      carYearMax,
      currentyear,
      currentDay,
      currentMonth,
      day,
      dobYear,
      disableIsNew,
      trimsList,
      manufacturerList,
      yearsList,
      modelList,
      checkdateLeadDriverDob,
      isDecimalValid,
      editClaimsCertificate,
      editClaims,
      loading,
      disableDateOne,
      yearMinusOne,
      yearMinuseighteen,
      onManufacturerChange,
      leadDriverDetailsBodyParam,
      onModelChange,
      onYearSelect,
      onTrimChange,
      getBrandNew,
      getEmirates,
      clearEmirates,
      getNationality,
      clearNationality,
      clearDlNationality,
      getDlNationality,
      getRegistraionDate,
      getHomeDrivingExperience,
      clearHomeDrivingExperience,
      getUaeDrivingExperience,
      clearUaeDrivingExperience,
      getNoClaim,
      clearNoClaim,
      getPolicyBeginDate,
      getGccSpec,
      getVehicleUse,
      getThirdParty,
      getDOB,
      getPolicyExpiry,
      addQuote,
      fetchCarLeadObj,
      getDuplicateDataExist,
      years,
      // manufacturers,
      models,
      trims,
      emirates,
      // nationality,
      countryList,
      // dlCountry,
      dlCountryList,
      uaeExperience,
      homeExperience,
      ncc,
      salesAgent,
      rules,
      resetClaims,
      onPolicyTypeChange,
      companyList,
      companiesList,
      onCompanyChange,
      policyData,
      disableSaveButton,
      disabledDate,
      clearIt,
      redirectToBike,
      carBikeValueCheck,
      otherGenders
    };
  },
});
